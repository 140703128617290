import actionTypes from './actionTypes';

const initialState = {
  openedModal: undefined,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_MODAL: {
      return { ...state, openedModal: action.payload };
    }
    case actionTypes.CLOSE_MODAL:
      return { ...state, openedModal: undefined };
    default:
      return state;
  }
};

export default appReducer;
