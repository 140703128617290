import React from 'react';
import Modal from './Modal';
import DealerForm from '../forms/DealerForm';
import StyledButton from '../../ui/StyledButton';
import FlexBox from '../../ui/FlexBox';

const DealerModal = ({ openModal, ...otherProps }) => {
  const formRef = React.createRef();

  const handleSubmit = () => {
    if (!formRef || !formRef.current) return;

    const {
      dealershipName,
      mainContact,
      email,
      phone,
      streetAddress,
      serviceMarketArea,
      website,
      howHearAbout,
      dealershipSize,
      yearsInBusiness,
      hasDUNS,
    } = formRef.current;

    // TODO: HANDLE ERRORS AND SEND FORM
  };

  return (
    <Modal
      fullWidth
      maxWidth="sm"
      title="Become A Dealer"
      content={<DealerForm complete ref={formRef} />}
      actions={(
        <FlexBox style={{ width: '100%' }} justifyContent="center">
          <StyledButton size="large" onClick={handleSubmit}>
            Submit
          </StyledButton>
        </FlexBox>
)}
      {...otherProps}
    />
  );
};

export default DealerModal;
