import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { TextField, FormControl, FormHelperText } from '@material-ui/core';
import FlexBox from '../../ui/FlexBox';
import useGlobalClasses from '../../utils/GlobalClasses';

const ForgotPasswordForm = (props, ref) => {
  const globalClasses = useGlobalClasses();

  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({});

  useImperativeHandle(
    ref,
    () => ({
      email,
      setErrors,
    }),
    [email],
  );

  const clearError = (key) => {
    setErrors({ ...errors, [key]: undefined });
  };
  return (
    <div>
      <FlexBox flexDirection="column" className={globalClasses.formFieldsContainer}>
        <FormControl error={Boolean(errors.email)}>
          <TextField
            required
            autoFocus
            id="forgot-password-form-email"
            label="Email Address"
            type="email"
            error={Boolean(errors.email)}
            className={globalClasses.formTextField}
            value={email}
            onChange={(evt) => {
              setEmail(evt.target.value);
              clearError('email');
            }}
            margin="normal"
          />
          {errors.email && <FormHelperText>{errors.email}</FormHelperText>}
        </FormControl>
      </FlexBox>
    </div>
  );
};

export default forwardRef(ForgotPasswordForm);
