import { combineReducers } from 'redux';
import appReducer from './app';
import userReducer from './user';

const rootReducer = combineReducers({
  appReducer,
  userReducer,
});

export default rootReducer;
