import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import useGlobalClasses from '../utils/GlobalClasses';
import Routes from '../constants/Routes';
import StyledButton from '../ui/StyledButton';
import FlexBox from '../ui/FlexBox';

const NotFound = () => {
  const globalClasses = useGlobalClasses();

  return (
    <div className={globalClasses.pageContainer}>
      <FlexBox
        alignItems="center"
        vSpacing="1.5rem"
        justifyContent="center"
        style={{ padding: '3rem 0', textAlign: 'center' }}
        flexDirection="column"
        className={globalClasses.wrapper}
      >
        <Typography variant="h2">Page Not Found</Typography>
        <Typography variant="h5">
          The page you are looking for might have been removed, had its name changed or is
          temporairly unavailable.
        </Typography>
        <Link className={globalClasses.navLink} to={Routes.home.url}>
          <StyledButton size="large">Go To Homepage</StyledButton>
        </Link>
      </FlexBox>
    </div>
  );
};

export default NotFound;
