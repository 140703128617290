import { createMuiTheme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import {
  LIGHT_BACKGROUND_COLOR,
  DARK_BACKGROUND_COLOR,
  PRIMARY_COLOR_LIGHT,
  PRIMARY_COLOR_LIGHTER,
  PRIMARY_COLOR,
} from '../constants/Style';

export const ThemeType = {
  Light: 'light',
  Dark: 'dark',
};

export const getTheme = (type = ThemeType.Light) => {
  const isLight = type === ThemeType.Light;

  return createMuiTheme({
    typography: {
      useNextVariants: true,
      h1: {
        fontWeight: 'bold',
      },
      h2: {
        fontWeight: 500,
      },
      h3: {
        fontWeight: 'bold',
      },
      h5: {
        fontWeight: 500,
      },
      h6: {
        fontWeight: 'bold',
        fontSize: '1.1rem',
        marginBottom: '.5rem',
      },
      body1: {
        fontSize: '1.4rem',
      },
      body2: {
        fontSize: '1.2rem',
      },
      fontFamily: [
        '"Helvetica Neue"',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    palette: {
      type,
      primary: { main: PRIMARY_COLOR, light: PRIMARY_COLOR_LIGHT, lighter: PRIMARY_COLOR_LIGHTER },
      secondary: red,
      grey: {
        primary: '#a6adb5',
        dark: 'rgb(40, 40, 40)',
        themeDark: (opacity = 1) => (isLight ? `rgba(215, 215, 215, ${opacity})` : `rgba(40, 40, 40, ${opacity})`),
      },
      text: {
        secondary: '#A6ADB4',
      },
      background: {
        default: isLight ? LIGHT_BACKGROUND_COLOR : DARK_BACKGROUND_COLOR,
      },
      social: {
        linkedIn: '#0e76a8',
      },
      themeColor: (opacity = 1) => (isLight ? `rgba(255, 255, 255, ${opacity})` : `rgba(26, 26, 29, ${opacity})`),
      oppositeColor: (opacity = 1) => (isLight ? `rgba(26, 26, 29, ${opacity})` : `rgba(255, 255, 255, ${opacity})`),
    },
    overrides: {
      MuiBackdrop: {
        root: { backgroundColor: 'rgba(0, 0, 0, .4)' },
      },
      MuiLink: {
        root: {
          color: isLight ? PRIMARY_COLOR : PRIMARY_COLOR_LIGHT,
        },
      },
    },
    get isLight() {
      return isLight;
    },
  });
};

