import actionTypes from './actionTypes';

export const loginStart = () => ({
  type: actionTypes.LOGIN_START,
});

export const loginResolve = () => ({
  type: actionTypes.LOGIN_RESOLVE,
});

export const signUpStart = () => ({
  type: actionTypes.SIGN_UP_START,
});

export const signUpResolve = () => ({
  type: actionTypes.SIGN_UP_RESOLVE,
});

export const forgotPasswordStart = () => ({
  type: actionTypes.FORGOT_PASSWORD_START,
});

export const forgotPasswordResolve = () => ({
  type: actionTypes.FORGOT_PASSWORD_RESOLVE,
});

export const resetPasswordStart = () => ({
  type: actionTypes.RESET_PASSWORD_START,
});

export const resetPasswordResolve = () => ({
  type: actionTypes.RESET_PASSWORD_RESOLVE,
});
