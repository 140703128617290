import { processErrorResponse, processResponse, createClient } from './Client';

export const logIn = async ({ email, password }) => {
  try {
    const res = await createClient().post('/authenticate', { email, password });
    return processResponse(res);
  } catch (err) {
    console.error(err);
    return processErrorResponse(err);
  }
};

export const signUp = async (data) => {
  try {
    const res = await createClient().post('/users', data);
    return processResponse(res);
  } catch (err) {
    console.error(err);
    return processErrorResponse(err);
  }
};
