import actionTypes from './actionTypes';

export const openModal = modalKey => ({
  type: actionTypes.OPEN_MODAL,
  payload: modalKey,
});

export const closeModal = () => ({
  type: actionTypes.CLOSE_MODAL,
});
