export default {
  appName: 'FLIP',
  home: 'Home',
  demo: 'Demo',
  about: 'About',
  testimonials: 'Testimonials',
  team: 'Team',
  contact: 'Contact',
  howTo: 'How To',
  login: 'Login',
  whyUs: 'Why Us',
  useCases: 'Use Cases',
  becomeADealer: 'Become A Dealer',
  learnMore: 'Learn More',
  homeIntroductionTitle: 'The Worlds Leading POS System',
  homeIntroductionSubtitle: 'Find out why major restaurant groups are migrating..',
  homeIntroductionParagraph:
    'For some reason — this country, this city, this neighborhood, this particular street — is the place you are living a majority of your life in.',
  explore: 'Explore',
  termsAndConditions: 'Terms & Conditions',
  policyPrivacy: 'Privacy Policy',
  faq: 'FAQ',
  yes: 'Yes',
  no: 'No',
  not_sure: 'Not Sure',
};
