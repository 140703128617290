import Routes from './Routes';
import teamMemeberDennis from '../../assets/images/team_member_dennis.png';
import teamMemeberChris from '../../assets/images/team_member_chris.png';
import teamMemeberJason from '../../assets/images/team_member_jason.png';
import teamMemeberGarret from '../../assets/images/team_member_garrett.png';
import teamMemeberTanner from '../../assets/images/team_member_tanner.png';
import teamMemeberErich from '../../assets/images/team_member_erich.png';
import teamMemeberRaj from '../../assets/images/team_member_raj.png';

export const HEADER_NAVIGATION_ITEMS = [
  Routes.home,
  Routes.softwareSolutions,
  Routes.demo,
  Routes.whyUs,
  Routes.contact,
];

export const PREFOOTER_NAVIGATION_ITEMS = [
  Routes.demo,
  Routes.whyUs,
  Routes.contact,
];

export const TEAM_MEMBERS = [
  {
    name: 'Dennis Andis',
    title: 'Project Manager',
    linkedInUrl: 'https://www.linkedin.com/in/dennis-andis-52a7a739/',
    image: teamMemeberDennis,
    quote:
      'Aliquip Lorem irure tempor non. Cillum cillum tempor excepteur sit ut. Amet elit exercitation incididunt laboris.',
  },
  {
    name: 'Chris Vincent',
    title: 'Founder Lead Dev',
    linkedInUrl: 'https://www.linkedin.com/in/chris-v-831068142/',
    image: teamMemeberChris,
    quote:
      'Aliquip Lorem irure tempor non. Cillum cillum tempor excepteur sit ut. Amet elit exercitation incididunt laboris.',
  },
  {
    name: 'Jason Lehman',
    title: 'Founder CEO',
    linkedInUrl: 'https://www.linkedin.com/in/jason-lehman-a7589819/',
    image: teamMemeberJason,
    quote:
      'Aliquip Lorem irure tempor non. Cillum cillum tempor excepteur sit ut. Amet elit exercitation incididunt laboris.',
  },
  {
    name: 'Garrett Heywood',
    title: 'COO',
    linkedInUrl: 'https://www.linkedin.com/in/garrett-heywood-455620111/',
    image: teamMemeberGarret,
    quote:
      'Aliquip Lorem irure tempor non. Cillum cillum tempor excepteur sit ut. Amet elit exercitation incididunt laboris.',
  },

  {
    name: 'Tanner Vinecke',
    title: 'Installation & Deployment Officer',
    linkedInUrl: 'https://www.linkedin.com/in/tanner-vinecke-44596691/',
    image: teamMemeberTanner,
    quote:
      'Aliquip Lorem irure tempor non. Cillum cillum tempor excepteur sit ut. Amet elit exercitation incididunt laboris.',
  },
  {
    name: 'Erich Kumpunen',
    title: 'Senior iOS Dev',
    linkedInUrl: 'https://www.linkedin.com/in/erich-kumpunen-978708166/',
    image: teamMemeberErich,
    quote:
      'Aliquip Lorem irure tempor non. Cillum cillum tempor excepteur sit ut. Amet elit exercitation incididunt laboris.',
  },   
  {
    name: 'Rajkumar Palanna',
    title: 'Certified Scrum Master/Project Manager',
    linkedInUrl: 'https://www.linkedin.com/in/dr-rajkumar-palanna-2532286/',
    image: teamMemeberRaj,
    quote:
      'Aliquip Lorem irure tempor non. Cillum cillum tempor excepteur sit ut. Amet elit exercitation incididunt laboris.',
  },   
    
    
];
