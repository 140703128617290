import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Card, CardMedia, Typography, CardContent } from '@material-ui/core';

const useStyles = makeStyles({
  media: {
    height: '200px',
    backgroundPositionY: 0,
    margin: '0',
  },
  content: {
    padding: '1.8rem',
  },
});

const HomeCard = ({ title, description, image }) => {
  const classes = useStyles();
  return (
    <Card>
      <CardMedia className={classes.media} title={title} image={image} />
      <CardContent className={classes.content}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="subtitle1">{description}</Typography>
      </CardContent>
    </Card>
  );
};

export default HomeCard;
