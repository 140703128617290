import { signUpStart, signUpResolve, loginStart, loginResolve } from './actions';
import * as AuthenticationAPI from '../../api/AuthenticationAPI';

export const loginThunk = ({ email, password }) => async (dispatch) => {
  dispatch(loginStart());

  const response = await AuthenticationAPI.logIn({ email, password });

  dispatch(loginResolve());

  return response;
};

export const signUpThunk = data => async (dispatch) => {
  dispatch(signUpStart());

  const response = await AuthenticationAPI.signUp(data);

  dispatch(signUpResolve());

  return response;
};
