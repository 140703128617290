import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Typography, Grid } from '@material-ui/core';
import { NavLink, withRouter, matchPath } from 'react-router-dom';
import useGlobalClasses from '../utils/GlobalClasses';
import Strings from '../constants/Strings';
import HomeCard from './HomeCard';
import { arraySwap, getCurrentRoute } from '../utils/Helper';
//import { useScrollAnimation, generalCardsAnimation } from '../utils/Animations';
import { PREFOOTER_NAVIGATION_ITEMS } from '../constants';
import Routes from '../constants/Routes';

const useStyles = makeStyles(theme => ({
  cardsContainer: { padding: '1.5rem 0', backgroundColor: theme.palette.grey.themeDark() },
  navigationMenu: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem',
    '& > *': {
      color: '#989494',
    },
  },
  cardsTitle: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    marginBottom: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}));

const PreFooter = (props) => {
  const classes = useStyles();
  const globalClasses = useGlobalClasses();
  const { location } = props;

  const theme = useTheme();

  const currentRoute = getCurrentRoute(location.pathname);

  // Set diffetent colors for Demo, since it's on dark mode.
  const isDemo = currentRoute && currentRoute.url === Routes.demo.url;

  useEffect(() => {
    const cardsContainer = document.getElementById('pre-footer-cards-container');
    // Shuffle items to randomize appearance
    const cardsContainerChildren = cardsContainer && arraySwap(Array.from(cardsContainer.children), 1, 2);

//    useScrollAnimation({
//      sceneOptions: { triggerElement: cardsContainer },
//      tween: generalCardsAnimation({ target: cardsContainerChildren }),
//    });
  }, []);

  // Filter nav items in case current route is included (do not render current route item)
  const renderedNavigationItems = PREFOOTER_NAVIGATION_ITEMS;
    // currentRoute && PREFOOTER_NAVIGATION_ITEMS.indexOf(currentRoute) !== -1
    // ? PREFOOTER_NAVIGATION_ITEMS.filter(route => route.url !== currentRoute.url)
    //: PREFOOTER_NAVIGATION_ITEMS;

  const renderNavigationCards = () => renderedNavigationItems.map(route => (
    <Grid key={route.url} xs={12} sm={6} md={4} item>
      <NavLink className={globalClasses.navLink} to={route.url}>
        <HomeCard title={route.title} image={route.image} description={route.description} />
      </NavLink>
    </Grid>
  ));

  return (
    <div className={classes.root}>
      <div
        style={{
          display: isDemo ? 'none': 'block',
        }}
        className={classes.cardsContainer}
      >
        <div className={globalClasses.wrapper}>
          <Typography
            style={{ color: isDemo ? 'white' : theme.palette.oppositeColor() }}
            id="pre-footer-title"
            className={classes.cardsTitle}
            variant="h5"
          >
            {Strings.explore}
          </Typography>
          <Grid
            id="pre-footer-cards-container"
            container
            justify="center"
            spacing={32}
            alignItems="center"
            wrap="wrap"
          >
            {renderNavigationCards()}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PreFooter);
