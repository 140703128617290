import React from 'react';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { ButtonBase } from '@material-ui/core';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    backgroundImage: 'linear-gradient(to right top, #783394, #7d2d89, #81277e, #832173, #841c69)',
    width: 'fit-content',
    fontWeight: 'bold',
    padding: '6px 18px',
    fontSize: '0.875rem',
    lineHeight: '1.75',
    boxShadow:
      '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    borderRadius: '4px',
    textTransform: 'uppercase',
    minWidth: '64px',
    color: 'white',
  },
  large: {
    padding: '8px 26px',
    fontSize: '0.9375rem',
  },
  small: {
    padding: '4px 10px',
    minWidth: '64px',
    fontSize: '0.8125rem',
  },
  fullWidth: {
    width: '100%',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.26)',
    boxShadow: 'none',
    background: 'rgba(0, 0, 0, 0.12)',
    cursor: 'default',
    pointerEvents: 'none',
  },
});

// TODO: Customize to match button colors, not only primary.
const useRippleStyles = makeStyles(theme => ({
  ripple: {
    // color: theme.palette.primary.main,
  },
}));

const StyledButton = (props) => {
  const classes = useStyles();
  const rippleClasses = useRippleStyles();

  const { children, className, size, variant, fullWidth, disabled, ...other } = props;

  return (
    <ButtonBase
      className={classNames(
        classes.root,
        classes[size],
        { [classes.fullWidth]: fullWidth },
        className,
        { [classes.disabled]: disabled },
      )}
      variant="contained"
      color={variant || 'primary'}
      TouchRippleProps={{ classes: rippleClasses }}
      {...other}
    >
      {children}
    </ButtonBase>
  );
};

StyledButton.propTypes = {
  size: PropTypes.oneOf(['large', 'small']),
};

export default StyledButton;
