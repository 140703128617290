import axios from 'axios';
import { BASE_API_URL, API_VERSION, API_TIMEOUT } from '../constants';

export const createClient = () => {
  const defaultOptions = {
    baseURL: `${BASE_API_URL}/${API_VERSION}`,
    timeout: API_TIMEOUT,
    withCredentials: true,
    headers: {
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  const instance = axios.create({
    ...defaultOptions,
  });

  return instance;
};

export const processResponse = (response) => {
  const result = {
    ...response,
    success: true,
    status: response.status || 500,
  };

  return result;
};

export const processErrorResponse = (response) => {
  const result = {
    ...response,
    success: true,
    error: true,
    status: response.status || 500,
  };

  return result;
};
