import _ from 'lodash';
import React, { useState } from 'react';
import { Typography, Link } from '@material-ui/core';
import { connect } from 'react-redux';
import Modal from './Modal';
import LoginForm from '../forms/LoginForm';
import FlexBox from '../../ui/FlexBox';
import StyledButton from '../../ui/StyledButton';
import { openModal, closeModal } from '../../../core/reducers/app/actions';
import { APP_MODAL } from '../../../core/reducers/app/enums';
import { isValidEmail } from '../../utils/Helper';
import { loginThunk } from '../../../core/reducers/user/thunks';
import Alert from '../Alert';

const LoginModal = ({ openModal, closeModal, loginThunk, loadingLogin, ...otherProps }) => {
  const formRef = React.createRef();

  const [alert, setAlert] = useState({});

  const handleSubmit = () => {
    if (!formRef || !formRef.current) return;

    const { email, password } = formRef.current;

    const errors = {};

    // Validate fields
    if (!email || !isValidEmail(email)) errors.email = 'You must enter a valid email.';
    if (!password) errors.password = 'You must enter a password.';

    if (!_.isEmpty(errors)) return formRef.current.setErrors(errors);

    // LOGIN API CALL
    loginThunk({ email, password }).then((response) => {
      if (response.error) {
        if (response.status === 500 || !response.message) {
          setAlert({ variant: 'error', content: 'Internal server error.' });
        } else {
          setAlert({ variant: 'error', content: response.message });
        }
      } else {
        closeModal();
        // TODO: Navigate to user profile?
      }
    });
  };

  const content = (
    <React.Fragment>
      {alert && alert.content && (
        <Alert
          message={alert.content}
          variant={alert.variant}
          onClose={() => setAlert(undefined)}
        />
      )}
      <LoginForm ref={formRef} />
    </React.Fragment>
  );

  const footer = (
    <React.Fragment>
      <Typography style={{ marginRight: '0.4rem' }} variant="subtitle1">
        Not a member yet?{' '}
      </Typography>
      <Link href="#" onClick={() => openModal(APP_MODAL.SIGN_UP)} variant="subtitle1">
        Sign Up
      </Link>
    </React.Fragment>
  );

  return (
    <Modal
      fullWidth
      maxWidth="xs"
      title="DEALER LOG IN"
      footer={footer}
      content={content}
      actions={(
        <FlexBox
          flexDirection="column"
          style={{ width: '100%' }}
          justifyContent="center"
          vSpacing="0.5rem"
        >
          <StyledButton disabled={loadingLogin} fullWidth size="large" onClick={handleSubmit}>
            Continue
          </StyledButton>
          <FlexBox justifyContent="flex-start">
            <Link href="#" variant="subtitle1" onClick={() => openModal(APP_MODAL.FORGOT_PASSWORD)}>
              Forgot Password
            </Link>
          </FlexBox>
        </FlexBox>
)}
      {...otherProps}
    />
  );
};

const mapState = ({ userReducer }) => ({
  loadingLogin: userReducer.loading,
});

const mapDispatch = {
  openModal,
  loginThunk,
  closeModal,
};

export default connect(
  mapState,
  mapDispatch,
)(LoginModal);
