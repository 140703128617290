import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField, Typography, MenuItem } from '@material-ui/core';
import classNames from 'classnames';
import FlexBox from '../../ui/FlexBox';
import { HasDUNSOption } from '../../constants/Enums';
import { capitalizeFirstLetter } from '../../utils/Helper';
import Strings from '../../constants/Strings';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    width: '100%',
    [theme.breakpoints.down(495)]: {
      width: '100%',
      margin: 0,
    },
  },
  numberField: {
    // width: '100px',
  },
  fieldsContainer: {
    width: '100%',
    '& > *': {
      width: '100%',
    },
    [theme.breakpoints.down(495)]: {
      flexDirection: 'column',
      '& > *:not(last_child)': {
        marginRight: 0,
        marginBottom: '15px',
      },
    },
  },
  fieldComplementText: {
    flexShrink: 0,
    marginBottom: '8px',
  },
}));

const DealerForm = (props, ref) => {
  const classes = useStyles();

  const { errors, complete } = props;

  const [dealershipName, setDealershipName] = useState('');
  const [mainContact, setMainContact] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const [serviceMarketArea, setServiceMarketArea] = useState('');
  const [website, setWebsite] = useState('');
  const [dealershipSize, setDealershipSize] = useState(0);
  const [yearsInBusiness, setYearsInBusiness] = useState(0);
  const [hasDUNS, setHasDUNDS] = useState(HasDUNSOption.NOT_SURE);
  const [dunsNumber, setDunsNumber] = useState('');
  const [howHearAbout, setHowHearAbout] = useState('');

  // Let parent get current values in order to handle submit
  useImperativeHandle(
    ref,
    () => ({
      dealershipName,
      mainContact,
      email,
      phone,
      streetAddress,
      serviceMarketArea,
      website,
      howHearAbout,
      dealershipSize,
      yearsInBusiness,
      hasDUNS,
      dunsNumber,
    }),
    [
      dealershipName,
      mainContact,
      email,
      phone,
      streetAddress,
      serviceMarketArea,
      website,
      howHearAbout,
      dealershipSize,
      yearsInBusiness,
      hasDUNS,
      dunsNumber,
    ],
  );

  const hasDUNSOptions = Object.values(HasDUNSOption).map(option => ({
    value: option,
    label: Strings[option],
  }));

  return (
    <form className={classes.container}>
      <FlexBox
        className={classes.fieldsContainer}
        flexDirection={!complete ? 'column' : 'row'}
        hSpacing="15px"
      >
        <TextField
          required
          autoFocus={Boolean(complete)}
          id="dealership-name"
          label="Dealership Name"
          className={classes.textField}
          error={errors && errors.dealershipName}
          value={dealershipName}
          onChange={evt => setDealershipName(evt.target.value)}
          margin="normal"
        />
        <TextField
          required
          id="main-contact"
          label="Main Contact"
          error={errors && errors.mainContact}
          className={classNames(classes.textField, { [classes.fullWidth]: !complete })}
          value={mainContact}
          onChange={evt => setMainContact(evt.target.value)}
          margin="normal"
        />
      </FlexBox>

      <FlexBox hSpacing="15px" className={classes.fieldsContainer}>
        <TextField
          required
          id="email"
          label="Email"
          type="email"
          error={errors && errors.email}
          className={classes.textField}
          value={email}
          onChange={evt => setEmail(evt.target.value)}
          margin="normal"
        />
        {complete && (
          <TextField
            required
            id="phone"
            label="Phone"
            type="tel"
            error={errors && errors.phone}
            className={classes.textField}
            value={phone}
            onChange={evt => setPhone(evt.target.value)}
            margin="normal"
          />
        )}
      </FlexBox>

      {complete && (
        <React.Fragment>
          <TextField
            required
            id="street-address"
            label="Street Address"
            fullWidth
            error={errors && errors.streetAddress}
            className={classNames(classes.textField, classes.fullWidth)}
            value={streetAddress}
            onChange={evt => setStreetAddress(evt.target.value)}
            margin="normal"
          />
          <FlexBox hSpacing="15px" className={classes.fieldsContainer}>
            <TextField
              required
              id="service-market-area"
              label="Service Market Area"
              error={errors && errors.serviceMarketArea}
              className={classes.textField}
              value={serviceMarketArea}
              onChange={evt => setServiceMarketArea(evt.target.value)}
              margin="normal"
            />
            <TextField
              id="website"
              label="Website"
              error={errors && errors.website}
              className={classes.textField}
              value={website}
              onChange={evt => setWebsite(evt.target.value)}
              margin="normal"
            />
          </FlexBox>

          <FlexBox hSpacing="15px" className={classes.fieldsContainer}>
            <FlexBox alignItems="flex-end" hSpacing="0.5rem">
              <TextField
                id="dealership-size"
                label="Current Dealership Size"
                value={dealershipSize}
                onChange={evt => setDealershipSize(evt.target.value)}
                type="number"
                className={classNames(classes.textField, classes.numberField)}
                InputProps={{ inputProps: { min: 0 } }}
                InputLabelProps={{
                  style: {
                    width: 'max-content',
                  },
                }}
                margin="normal"
              />
              <Typography variant="subtitle2" className={classes.fieldComplementText}>
                clients served
              </Typography>
            </FlexBox>
            <FlexBox alignItems="flex-end" hSpacing="0.5rem">
              <TextField
                id="years-in-business"
                label="How long have you been in business?"
                value={yearsInBusiness}
                onChange={evt => setYearsInBusiness(evt.target.value)}
                type="number"
                className={classNames(classes.textField, classes.numberField)}
                InputProps={{ inputProps: { min: 0 } }}
                InputLabelProps={{
                  style: {
                    width: 'max-content',
                  },
                }}
                margin="normal"
              />
              <Typography variant="subtitle2" className={classes.fieldComplementText}>
                years
              </Typography>
            </FlexBox>
          </FlexBox>

          <TextField
            id="has-DUNS"
            select
            label="Do you have a DUNS number?"
            className={classes.textField}
            value={hasDUNS}
            onChange={evt => setHasDUNDS(evt.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          >
            {hasDUNSOptions.map(option => (
              <MenuItem key={option} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          {hasDUNS === HasDUNSOption.YES && (
            <TextField
              id="duns-number"
              label="DUNS Number"
              error={errors && errors.dunsNumber}
              className={classes.textField}
              value={dunsNumber}
              type="number"
              onChange={evt => setDunsNumber(evt.target.value)}
              margin="normal"
            />
          )}

          <TextField
            id="how-hear-about"
            label="How'd You Hear About Us"
            fullWidth
            multiline
            rows="2"
            rowsMax="2"
            error={errors && errors.howHearAbout}
            className={classNames(classes.textField, classes.fullWidth)}
            value={howHearAbout}
            onChange={evt => setHowHearAbout(evt.target.value)}
            margin="normal"
          />
        </React.Fragment>
      )}
    </form>
  );
};

export default forwardRef(DealerForm);
