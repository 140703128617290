import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/styles';

const FlexBox = styled(
  ({
    alignItems,
    justifyContent,
    flexDirection,
    vSpacing,
    hSpacing,
    responsive,
    responsiveBreakpoint,
    flexShrink,
    ...props
  }) => <div {...props} />,
)({
  display: 'flex',
  flexDirection: p => p.flexDirection,
  flexShrink: p => p.flexShrink,
  justifyContent: p => p.justifyContent,
  alignItems: p => p.alignItems,
  '& > *:not(:last-child)': {
    marginBottom: p => p.vSpacing,
    marginRight: p => p.hSpacing,
  },
});

FlexBox.propTypes = {
  alignItems: PropTypes.string,
  justifyContent: PropTypes.string,
  hSpacing: PropTypes.string,
  vSpacing: PropTypes.string,
  flexDirection: PropTypes.string,
  flexShrink: PropTypes.string,
};

export default FlexBox;
