export default {
  LOGIN_START: 'USER__LOGIN_START',
  LOGIN_RESOLVE: 'USER__LOGIN_RESOLVE',
  SIGN_UP_START: 'USER__SIGN_UP_START',
  SIGN_UP_RESOLVE: 'USER__SIGN_UP_RESOLVE',
  FORGOT_PASSWORD_START: 'USER__FORGOT_PASSWORD_START',
  FORGOT_PASSWORD_RESOLVE: 'USER__FORGOT_PASSWORD_RESOLVE',
  RESET_PASSWORD_START: 'USER__RESET_PASSWORD_START',
  RESET_PASSWORD_RESOLVE: 'USER__RESET_PASSWORD_RESOLVE',
  CONFIRM_EMAIL_START: 'USER__CONFIRM_EMAIL_START',
  CONFIRM_EMAIL_RESOLVE: 'USER__CONFIRM_EMAIL_RESOLVE',
};
