import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { TextField, FormControl, FormHelperText } from '@material-ui/core';
import FlexBox from '../../ui/FlexBox';
import useGlobalClasses from '../../utils/GlobalClasses';

const ResetPasswordForm = (props, ref) => {
  const globalClasses = useGlobalClasses();

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [errors, setErrors] = useState({});

  useImperativeHandle(
    ref,
    () => ({
      password,
      passwordConfirmation,
      setErrors,
    }),
    [password, passwordConfirmation],
  );

  const clearError = (key) => {
    setErrors({ ...errors, [key]: undefined });
  };

  return (
    <div>
      <FlexBox flexDirection="column" className={globalClasses.formFieldsContainer}>
        <FormControl error={Boolean(errors.password)}>
          <TextField
            required
            autoFocus
            type="password"
            id="reset-password-form-password"
            label="Password"
            error={Boolean(errors.password)}
            className={globalClasses.formTextField}
            value={password}
            onChange={(evt) => {
              setPassword(evt.target.value);
              clearError('password');
            }}
            margin="normal"
          />
          {errors.password && <FormHelperText>{errors.password}</FormHelperText>}
        </FormControl>

        <FormControl error={Boolean(errors.passwordConfirmation)}>
          <TextField
            required
            type="password"
            id="reset-password-form-password-confirmation"
            label="Password Confirmation"
            error={Boolean(errors.passwordConfirmation)}
            className={globalClasses.formTextField}
            value={passwordConfirmation}
            onChange={(evt) => {
              setPasswordConfirmation(evt.target.value);
              clearError('passwordConfirmation');
            }}
            margin="normal"
          />
          {errors.passwordConfirmation && (
            <FormHelperText>{errors.passwordConfirmation}</FormHelperText>
          )}
        </FormControl>
      </FlexBox>
    </div>
  );
};

export default forwardRef(ResetPasswordForm);
