import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import App from './web/App';
import * as serviceWorker from './serviceWorker';
import dataStore from './core/stores/DataStore';
import { getTheme, ThemeType } from './web/utils/Theme';

const Root = () => {
  const [theme, setTheme] = useState(ThemeType.Dark);

  const toggleTheme = () => {
    setTheme(ThemeType.Dark);
  };

  return (
    <MuiThemeProvider theme={getTheme(theme)}>
      <ThemeProvider theme={getTheme(theme)}>
        <CssBaseline />
        <Provider store={dataStore}>
          <BrowserRouter>
            <App toggleTheme={toggleTheme} />
          </BrowserRouter>
        </Provider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
