import { makeStyles } from '@material-ui/styles';
import { HEADER_HEIGHT } from '../constants/Layout';

const useGlobalClasses = makeStyles(theme => ({
  formTextField: {
    width: '100%',
    [theme.breakpoints.down(495)]: {
      width: '100%',
      margin: 0,
    },
  },
  formFieldsContainer: {
    width: '100%',
    [theme.breakpoints.down(495)]: {
      flexDirection: 'column',
      '& > *:not(last_child)': {
        marginRight: 0,
        marginBottom: '15px',
      },
    },
  },
  navLink: {
    cursor: 'pointer',
    color: 'inherit',
    textDecoration: 'none',
    height: 'fit-content',
    width: 'fit-content',
  },
  pageContainer: {
    position: 'relative',
    paddingTop: HEADER_HEIGHT,
  },
  homeScrollableSection: {
    height: '100vh',
    position: 'relative',
    overflow: 'hidden',
  },
  title: {
    marginBottom: '20px',
    fontSize: '1.25rem',
    fontWeight: 600,
  },
  heading1: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '5rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.8rem',
    },
  },
  heading2: {
    marginBottom: '2.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.8rem',
    },
  },
  subtitle: {
    fontSize: '1rem',
    marginBottom: '20px',
    fontWeight: '600',
  },
  paragraph: {
    fontSize: '1.2rem',
    margin: '2rem 0',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
  lead: {
    fontSize: '1.625rem',
    lineHeight: '2.25rem',
    margin: '1.5rem 0',
    letterSpacing: '-.03062rem',
    // fontWeight: 500,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.125rem',
      lineHeight: '1.5rem',
    },
  },
  innerWrapper: {
    maxWidth: '83.3333%',
    margin: '0 auto',
  },
  contentWrapper: {
    paddingRight: '1rem',
    paddingLeft: '1rem',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',

    // Full width for (xs, extra-small: 0px or larger) and (sm, small: 600px or larger)
    [theme.breakpoints.up('md')]: {
      // medium: 960px or larger
      maxWidth: 960,
    },
    [theme.breakpoints.up('lg')]: {
      // large: 1280px or larger
      maxWidth: 1170,
    },
    [theme.breakpoints.up('xl')]: {
      // extra-large: 1920px or larger
      width: 1366,
      maxWidth: 1366,
    },
  },
  wrapper: {
    paddingRight: '1rem',
    paddingLeft: '1rem',
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '100%',

    // Full width for (xs, extra-small: 0px or larger) and (sm, small: 600px or larger)
    [theme.breakpoints.up('md')]: {
      // medium: 960px or larger
      width: '95%',
      maxWidth: 1366,
    },
    [theme.breakpoints.up('lg')]: {
      // large: 1280px or larger
      maxWidth: 1366,
    },
    [theme.breakpoints.up('xl')]: {
      // extra-large: 1920px or larger
      width: 1366,
      maxWidth: 1366,
    },
  },
}));

export default useGlobalClasses;
