import React from 'react';
import uuid4 from 'uuid4';
import PropTypes from 'prop-types';
import { Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import FlexBox from '../../ui/FlexBox';
import flipLogo from '../../../assets/images/flip_symbol_only.png';

const useStyles = makeStyles(theme => ({
  paper: {
    margin: '20px',
  },
  header: {
    width: '100%',
    padding: '24px 24px 20px',
  },
  headerContent: {
    width: '100%',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    paddingBottom: '1rem',
  },
  flipLogo: {
    width: '50px',
  },
  title: {
    fontSize: '1.6rem',
    margin: 0,
  },
  description: {
    padding: '0.3rem 24px',
  },
  actions: {
    margin: '8px 24px',
    '& > *': {
      margin: 0,
    },
  },
  footer: {
    padding: '1rem',
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
    marginTop: '1rem',
  },
}));

const Modal = (props) => {
  const {
    onClose,
    title,
    content,
    actions,
    customHeader,
    customFooter,
    footer,
    description,
    ...other
  } = props;

  const classes = useStyles();

  // Create a id for each modal
  const titleId = uuid4();

  const header = title ? (
    <FlexBox className={classes.header}>
      <FlexBox
        className={classes.headerContent}
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        <img src={flipLogo} className={classes.flipLogo} alt="FLIP Logo" />
      </FlexBox>
    </FlexBox>
  ) : (
    undefined
  );

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      onClose={onClose}
      aria-labelledby={titleId}
      {...other}
    >
      {customHeader || header}
      {description && (
        <Typography variant="subtitle1" className={classes.description}>
          {description}
        </Typography>
      )}
      <DialogContent>{content}</DialogContent>
      {actions && <DialogActions className={classes.actions}>{actions}</DialogActions>}
      {customFooter
        || (footer && (
          <FlexBox className={classes.footer} alignItems="center" justifyContent="center">
            {footer}
          </FlexBox>
        ))}
    </Dialog>
  );
};

Modal.propTypes = {
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  customHeader: PropTypes.node,
  customFooter: PropTypes.node,
  footer: PropTypes.node,
  content: PropTypes.node,
  actions: PropTypes.node,
};

export default Modal;
