import actionTypes from './actionTypes';

const initialState = {};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_START:
      return { ...state, loading: true };

    case actionTypes.LOGIN_RESOLVE:
      return { ...state, loading: false };

    case actionTypes.SIGN_UP_START:
      return { ...state, loading: true };

    case actionTypes.SIGN_UP_RESOLVE:
      return { ...state, loading: false };

    case actionTypes.FORGOT_PASSWORD_START:
      return { ...state, loading: true };

    case actionTypes.FORGOT_PASSWORD_RESOLVE:
      return { ...state, loading: false };

    case actionTypes.RESET_PASSWORD_START:
      return { ...state, loading: true };

    case actionTypes.RESET_PASSWORD_RESOLVE:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default userReducer;
