import _ from 'lodash';
import { matchPath } from 'react-router-dom';
import Routes from '../constants/Routes';

// t = current time
// b = start value
// c = change in value
// d = duration
const easeInOutQuad = (t, b, c, d) => {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t -= 1;
  return (-c / 2) * (t * (t - 2) - 1) + b;
};

export const scrollTo = (element, to, duration) => {
  const start = element.scrollTop;

  const change = to - start;

  let currentTime = 0;

  const increment = 20;

  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    element.scrollTop = val;
    if (currentTime < duration) {
      setTimeout(animateScroll, increment);
    }
  };
  animateScroll();
};

export const hexToRGB = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);

  const g = parseInt(hex.slice(3, 5), 16);

  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha !== null && alpha !== undefined) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
};

export const arrayMove = (arr, oldIndex, newIndex) => {
  if (oldIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k) {
      arr.push(undefined);
      k -= 1;
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const arraySwap = (arr, firstIndex, secondIndex) => {
  if (!arr || !_.isArray(arr) || !arr[firstIndex] || !arr[secondIndex]) return undefined;
  const newArr = [...arr];
  const res = arr[firstIndex];
  newArr[firstIndex] = arr[secondIndex];
  newArr[secondIndex] = res;
  return newArr;
};

export const getCurrentRoute = pathname => Object.values(Routes).find(route => matchPath(pathname, { exact: true, path: route.url }));

export const isValidEmail = (email) => {
  if (!email) return false;

  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);
