import { lazy } from 'react';
import HomeIcon from '@material-ui/icons/Home';
import DemoIcon from '@material-ui/icons/TouchApp';
import SoftwareSolutionsIcon from '@material-ui/icons/Devices';
import ContactIcon from '@material-ui/icons/Mail';
import WhyUsIcon from '@material-ui/icons/Info';
import Strings from './Strings';
import homeScreenshot from '../../assets/images/home_screenshot.png';
import whyUsScreenshot from '../../assets/images/whyus_screenshot.png';
import mapDark from '../../assets/images/map_dark.jpg';

export default {
  home: {
    url: '/',
    title: Strings.home,
    private: false,
    icon: () => HomeIcon,
    image: homeScreenshot,
    description: "Let's take it from the top. ",
    component: lazy(() => import('../pages/home')),
  },
  softwareSolutions: {
    url: '/software-solutions',
    title: 'Software Solutions',
    private: false,
    icon: () => SoftwareSolutionsIcon,
    image: homeScreenshot,
    component: lazy(() => import('../pages/softwaresolutions')),
  },
  demo: {
    url: '/demo',
    title: Strings.demo,
    private: false,
    icon: () => DemoIcon,
    image: homeScreenshot,
    description: 'A more in-depth journey through our software and its segments.',
    component: lazy(() => import('../pages/demo')),
  },
  whyUs: {
    url: '/why-us',
    title: Strings.whyUs,
    private: false,
    icon: () => WhyUsIcon,
    image: whyUsScreenshot,
    description: 'Find out what sets us apart and what others are saying about our software?',
    component: lazy(() => import('../pages/whyus')),
  },
  contact: {
    url: '/contact',
    title: Strings.contact,
    private: false,
    icon: () => ContactIcon,
    image: mapDark,
    description:
      'We understand finding a partner in this field can be difficult, reach out and we will walk through it together.',
    component: lazy(() => import('../pages/contact')),
  },
  login: {
    url: '/login',
    title: Strings.login,
    private: false,
  },
  termsAndConditions: {
    url: '/terms-and-conditions',
    title: Strings.termsAndConditions,
    private: false,
    component: lazy(() => import('../pages/termsandconditions')),
  },
  privacyPolicy: {
    url: '/privacy-policy',
    title: Strings.policyPrivacy,
    private: false,
    component: lazy(() => import('../pages/privacypolicy')),
  },
  faq: {
    url: '/faq',
    title: Strings.faq,
    private: false,
    component: lazy(() => import('../pages/faq')),
  },
};
