import _ from 'lodash';
import React from 'react';
import Modal from './Modal';
import StyledButton from '../../ui/StyledButton';
import ResetPasswordForm from '../forms/ResetPasswordForm';

const ResetPasswordModal = (props) => {
  const formRef = React.createRef();

  const handleSubmit = () => {
    if (!formRef || !formRef.current) return;

    const { password, passwordConfirmation } = formRef.current;

    const errors = {};

    // Validate fields
    if (!password) errors.password = 'You must enter a new password.';
    else if (!passwordConfirmation) errors.passwordConfirmation = 'You must enter your new password again.';

    // TODO: VALIDATE PASSWORD ADDITIONAL REQUIREMENTS

    if (password && passwordConfirmation && password !== passwordConfirmation) errors.password = 'Password and its confirmation must coincide.';

    if (!_.isEmpty(errors)) return formRef.current.setErrors(errors);

    // TODO: RESET PASSWORD API CALL
  };

  return (
    <Modal
      fullWidth
      maxWidth="xs"
      title="CHANGE PASSWORD"
      description="Please enter your new password."
      content={<ResetPasswordForm ref={formRef} />}
      actions={(
        <StyledButton fullWidth size="large" onClick={handleSubmit}>
          Continue
        </StyledButton>
)}
      {...props}
    />
  );
};

export default ResetPasswordModal;
