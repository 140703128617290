import _ from 'lodash';
import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { connect } from 'react-redux';
import Modal from './Modal';
import StyledButton from '../../ui/StyledButton';
import SignUpForm from '../forms/SignUpForm';
import { openModal } from '../../../core/reducers/app/actions';
import { APP_MODAL } from '../../../core/reducers/app/enums';
import { isValidEmail } from '../../utils/Helper';
import { signUpThunk } from '../../../core/reducers/user/thunks';

const SignUpModal = ({ openModal, signUpThunk, ...otherProps }) => {
  const formRef = React.createRef();

  const handleSubmit = () => {
    if (!formRef || !formRef.current) return;

    const { email, password } = formRef.current;

    const errors = {};

    // Validate fields
    if (!email || !isValidEmail(email)) errors.email = 'You must enter a valid email.';

    // TODO: VALIDATE PASSWORD ADDITIONAL REQUIREMENTS
    if (!password) errors.password = 'You must enter a password.';

    if (!_.isEmpty(errors)) return formRef.current.setErrors(errors);

    signUpThunk({ email, password, name: 'dealer', role: 'dealer' });
  };

  const footer = (
    <React.Fragment>
      <Typography style={{ marginRight: '0.4rem' }} variant="subtitle1">
        Already a member?{' '}
      </Typography>
      <Link href="#" variant="subtitle1" onClick={() => openModal(APP_MODAL.LOGIN)}>
        Sign In
      </Link>
    </React.Fragment>
  );

  return (
    <Modal
      fullWidth
      maxWidth="xs"
      title="CREATE ACCOUNT"
      footer={footer}
      content={<SignUpForm ref={formRef} />}
      actions={(
        <StyledButton fullWidth size="large" onClick={handleSubmit}>
          Continue
        </StyledButton>
)}
      {...otherProps}
    />
  );
};

const mapDispatch = {
  openModal,
  signUpThunk,
};

export default connect(
  null,
  mapDispatch,
)(SignUpModal);
