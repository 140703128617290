import React, { Suspense, useEffect } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import ScrollMagic from 'scrollmagic';
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';
import { TimelineMax } from 'gsap';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import Routes from './constants/Routes';
import Header from './components/Header';
import Footer from './components/Footer';
import PreFooter from './components/PreFooter';
import { getCurrentRoute } from './utils/Helper';
import Loading from './components/Loading';
import LoginModal from './components/modals/LoginModal';
import SignUpModal from './components/modals/SignUpModal';
import ForgotPasswordModal from './components/modals/ForgotPasswordModal';
import ResetPasswordModal from './components/modals/ResetPasswordModal';
import { APP_MODAL } from '../core/reducers/app/enums';
import { closeModal } from '../core/reducers/app/actions';
import DealerModal from './components/modals/DealerModal';
import NotFound from './pages/NotFound';

const {
  home,
  demo,
  whyUs,
  contact,
  termsAndConditions,
  privacyPolicy,
  faq,
  softwareSolutions,
} = Routes;
const publicRoutes = [
  home,
  softwareSolutions,
  demo,
  whyUs,
  contact,
  termsAndConditions,
  privacyPolicy,
  faq,
];

// ScrollMagic / GSAP tweak for animations
//ScrollMagicPluginGsap(ScrollMagic, TimelineMax);

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'hidden',
  },
}));

const App = (props) => {
  const classes = useStyles();
  const { toggleTheme, location, openedModal, closeModal } = props;

  const currentRoute = getCurrentRoute(location.pathname);

  // Scroll top on page change
  useEffect(() => {
    if (!location.hash) window.scrollTo(0, 0);
  }, [props.location]);

  const renderPublicRoutes = () => publicRoutes.map((route) => {
    const Component = route.component;
    return (
      <Route
        exact
        key={route.url}
        path={route.url}
        render={renderProps => <Component {...renderProps} />}
      />
    );
  });

  const siteMetadata = (
    <Helmet
      title={`${currentRoute ? `${currentRoute.title} - ` : ''}Flip POS Frontend`}
      meta={[
        {
          property: 'og:title',
          content: `${currentRoute ? `${currentRoute.title} - ` : ''}Flip POS Frontend`,
        },
        {
          property: 'og:description',
          content: currentRoute ? currentRoute.description || '' : '',
        },
        { property: 'og:type', content: 'website' },
        { property: 'og:site_name', content: 'Flip POS' },
        { name: 'robots', content: 'index, follow' },
        { name: 'language', content: 'English' },
      ]}
    />
  );

  return (
    <div id="app-container" className={classes.root}>
      {siteMetadata}
      <Header />
      <Suspense fallback={<Loading />}>
        <Switch>
          {renderPublicRoutes()}
          <Route component={NotFound} />
        </Switch>
      </Suspense>
      <PreFooter />
      <Footer toggleTheme={toggleTheme} />
      <LoginModal open={openedModal === APP_MODAL.LOGIN} onClose={() => closeModal()} />
      <SignUpModal open={openedModal === APP_MODAL.SIGN_UP} onClose={() => closeModal()} />
      <ForgotPasswordModal
        open={openedModal === APP_MODAL.FORGOT_PASSWORD}
        onClose={() => closeModal()}
      />
      <ResetPasswordModal
        open={openedModal === APP_MODAL.RESET_PASSWORD}
        onClose={() => closeModal()}
      />
      <DealerModal open={openedModal === APP_MODAL.DEALER_MODAL} onClose={() => closeModal()} />
    </div>
  );
};

const mapDispatch = {
  closeModal,
};

const mapState = ({ appReducer }) => {
  const { openedModal } = appReducer;
  return { openedModal };
};

export default withRouter(
  connect(
    mapState,
    mapDispatch,
  )(App),
);
