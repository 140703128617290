import React, { useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Typography, Paper, InputBase, IconButton } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import DarkIcon from '@material-ui/icons/Brightness4';
import LightIcon from '@material-ui/icons/Brightness7';
import { connect } from 'react-redux';
import FlexBox from '../ui/FlexBox';
import logoWhite from '../../assets/images/logo_white.png';
import Strings from '../constants/Strings';
import useGlobalClasses from '../utils/GlobalClasses';
import StyledButton from '../ui/StyledButton';
import Routes from '../constants/Routes';
import { ThemeType } from '../utils/Theme';
import { openModal } from '../../core/reducers/app/actions';
import { APP_MODAL } from '../../core/reducers/app/enums';

const { home, demo, whyUs, contact, termsAndConditions, faq, privacyPolicy } = Routes;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: 0,
    color: theme.palette.common.white,

    backgroundColor:
      theme.palette.type === ThemeType.Light
        ? theme.palette.grey.primary
        : theme.palette.themeColor(),
  },
  wrapper: {
    padding: '4rem 1rem',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '2rem 1rem',
    },
  },
  mainList: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      '& > *:not(last_child)': {
        marginBottom: '2rem',
        marginRight: '0 !important',
      },
      '& > *': {
        alignItems: 'center',
      },
    },
  },
  newsletterContainer: {
    padding: '.2rem .4rem .2rem 1.4rem',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '400px',
      maxWidth: '80%',
    },
  },
  newsletterInput: {
    flexGrow: 1,
  },
  menuContainer: {
    justifyContent: 'space-evenly',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
      alignItems: 'flex-start',
    },
  },
  menuList: {
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  menuItem: {
    minWidth: 'max-content',
    fontWeight: '600',
    transition: '.2s',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  iconsContainer: {
    '& > *': {
      width: '45px',
    },
  },
  themeSwitcher: {
    width: '45px',
    alignSelf: 'flex-end',
    position: 'absolute',
    right: '2rem',
    bottom: '2rem',
    [theme.breakpoints.down(1700)]: {
      right: '50%',
      transform: 'translateX(50%)',
      bottom: '1rem',
    },
  },
  becomeADealerButton: {
    fontSize: '1.2rem',
  },
}));

const leftNavigationItems = [home, demo, whyUs, contact];
const rightNavigationItems = [termsAndConditions, privacyPolicy, faq];

const Footer = (props) => {
  const classes = useStyles();
  const globalClasses = useGlobalClasses();
  const theme = useTheme();

  const { toggleTheme, openModal } = props;

  const renderNavigation = () => (
    <FlexBox className={classes.menuContainer} alignItems="flex-start" hSpacing="1.5rem">
      <FlexBox className={classes.menuList} flexDirection="column" vSpacing="0.8rem">
        {leftNavigationItems.map(item => (
          <NavLink className={globalClasses.navLink} key={item.url} to={item.url}>
            <Typography color="inherit" className={classes.menuItem} variant="subtitle2">
              {item.title}
            </Typography>
          </NavLink>
        ))}
      </FlexBox>
      <FlexBox className={classes.menuList} flexDirection="column" vSpacing="0.8rem">
        {rightNavigationItems.map(item => (
          <NavLink className={globalClasses.navLink} key={item.url} to={item.url}>
            <Typography color="inherit" className={classes.menuItem} variant="subtitle2">
              {item.title}
            </Typography>
          </NavLink>
        ))}
      </FlexBox>
    </FlexBox>
  );

  return (
    <footer className={classes.root}>
      <div className={classNames(globalClasses.wrapper, classes.wrapper)}>
        <FlexBox className={classes.mainList} hSpacing="2rem" justifyContent="space-between">
         
            {renderNavigation()}
            <div className={classes.iconsContainer}>
              <IconButton color="inherit" onClick={() => window.open('https://www.Linkedin.com')}>
                <i className="fab fa-instagram" />
              </IconButton>
              <IconButton color="inherit">
                <i className="fab fa-twitter" onClick={() => window.open('https://www.twitter.com')}/>
              </IconButton>
              <IconButton color="inherit">
                <i className="fab fa-facebook-f" onClick={() => window.open('https://www.facebook.com')} />
              </IconButton>
             <IconButton color="inherit">
                <i className="fas fa-globe-americas" onClick={() => window.open('https://flippos.com/contact')} />
              </IconButton>
          </div>
        </FlexBox>
      </div>
    </footer>
  );
};

const mapDispatch = { openModal };

export default connect(
  null,
  mapDispatch,
)(Footer);
