import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  List,
  Menu,
  MenuItem,
  Avatar,
} from '@material-ui/core';
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/styles';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronRight from '@material-ui/icons/ChevronRight';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { withRouter, NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  HEADER_HEIGHT,
  MOBILE_DRAWER_WIDTH,
  HEADER_LOGO_WIDTH,
  DRAWER_LOGO_WIDTH,
  HEADER_DRAWER_BREAKPOINT,
} from '../constants/Layout';
import profilePlaceholder from '../../assets/images/profile_placeholder.jpg';
import Routes from '../constants/Routes';
import logo from '../../assets/images/logo.png';
import logoWhite from '../../assets/images/logo_white.png';
import useGlobalClasses from '../utils/GlobalClasses';
import { HEADER_NAVIGATION_ITEMS } from '../constants';
import { openModal } from '../../core/reducers/app/actions';
import { APP_MODAL } from '../../core/reducers/app/enums';
import StyledButton from '../ui/StyledButton';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
    height: HEADER_HEIGHT,
    boxShadow: 'none',
    backgroundColor: theme.palette.themeColor(0.8),
    padding: '0 2rem',
    backdropFilter: 'blur(2em)',
    overflow: 'hidden',
    [theme.breakpoints.down('xs')]: {
      padding: '0 1rem',
    },
  },
  appLogo: {
    height: 'fit-content',
    '& > img': {
      width: HEADER_LOGO_WIDTH,
    },
  },
  appBarWrapper: {
    height: '100%',
  },
  toolBar: {
    height: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 0,
    width: '100%',
  },
  desktopMenu: {
    position: 'absolute',
    right: 0,
  },
  desktopMenuItem: {
    fontSize: '0.9rem',
    '& span': {
      fontWeight: 'bold',
      color: theme.palette.oppositeColor(0.6),
    },
  },
  selectedDesktopMenuItem: {
    '& span': {
      color: theme.palette.primary.main,
    },
  },
  mobileMenuButton: {
    position: 'absolute',
    right: 0,
    padding: '.6rem',
  },
  mobileDrawerPaper: {
    width: MOBILE_DRAWER_WIDTH,
    background: theme.palette.themeColor(),
    boxShadow: '0 0 60px rgba(0, 0, 0, 0.6)',
  },
  mobileDrawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: HEADER_HEIGHT,
    padding: '0 1.4rem',
  },
  mobileDrawerMenu: {},
  mobileMenuItem: {
    padding: '16px 3rem',
    '& span': {
      fontWeight: '500',
      fontSize: '0.95rem',
      textTransform: 'uppercase',
      color: theme.palette.oppositeColor(0.6),
    },
  },
  mobileMenuItemIcon: {
    marginRight: '20px',
  },
  selectedMobileMenuItem: {
    '& span, & svg': {
      color: theme.palette.primary.main,
      fontWeight: '800',
    },
    backgroundColor: `${theme.palette.primary.lighter} !important`,
  },
  accountMenuPaper: {
    marginTop: '2rem',
  },
  accountMenuButton: {
    padding: '.3rem',
    marginLeft: '2rem',
  },
  drawerAppLogo: {
    alignSelf: 'center',
    marginBottom: '1rem',
    '& > img': {
      width: DRAWER_LOGO_WIDTH,
    },
  },
  profileAvatarDesktop: {
    width: '35px',
    height: '35px',
  },
  profileAvatarMobile: {
    width: '24px',
    height: '24px',
  },
  becomeADealerButton: {
    marginLeft: '8px',
    [theme.breakpoints.down(HEADER_DRAWER_BREAKPOINT)]: {
      marginLeft: '0',
      alignSelf: 'center',
    },
  },
}));

const Header = (props) => {
  const { location, openModal } = props;
  const classes = useStyles();
  const globalClasses = useGlobalClasses();
  const theme = useTheme();

  const [drawerOpen, setDrawerOpen] = useState();

  const [accountMenuData, setAccountMenuData] = useState({
    anchorEl: undefined,
    hover: undefined,
  });

  const accountMenuOpen = Boolean(accountMenuData.anchorEl);
  const isDesktop = useMediaQuery(theme.breakpoints.up(HEADER_DRAWER_BREAKPOINT));

  // TODO: Get computed value from app state
  const isAuthenticated = false;

  const usedLogo = theme.isLight ? logo : logoWhite;

  // Handlers

  const handleAccountMenuClose = () => {
    setAccountMenuData({ anchorEl: undefined, hover: false });
  };

  const handleAccountButtonHover = (evt) => {
    evt.preventDefault();

    // Prevent from multiple state updates
    if (accountMenuData.hover) return;

    // Indicate hovering state. State updating will not be triggered multiple times
    setAccountMenuData({ ...accountMenuData, hover: true });

    const evtTarget = evt.currentTarget;

    setTimeout(() => {
      setAccountMenuData({ ...accountMenuData, anchorEl: evtTarget });
    }, 0.45); // Wait 0.45 seconds for smooth UX
  };

  // Components

  const ProfileAvatar = profileAvatarProps => (
    <Avatar
      className={classes.profileAvatarDesktop}
      {...profileAvatarProps}
      alt="Profile"
      src={profilePlaceholder}
    />
  );

  // Renderers

  // TODO: Render items based on authentication state
  const renderDesktopAccountMenu = () => (
    // TODO: Render menu items programatically, based on real urls.
    <Menu
      PopoverClasses={{ paper: classes.accountMenuPaper }}
      className={classes.accountMenu}
      id="account-menu"
      anchorEl={accountMenuData.anchorEl}
      open={accountMenuOpen}
      onClose={handleAccountMenuClose}
    >
      <MenuItem
        onClick={() => {
          handleAccountMenuClose();
          openModal(APP_MODAL.LOGIN);
        }}
      >
        Login
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleAccountMenuClose();
          openModal(APP_MODAL.SIGN_UP);
        }}
      >
        Sign Up
      </MenuItem>
    </Menu>
  );

  const renderMobileAccountMenuItems = () => (
    <React.Fragment>
      <ListItem
        className={classes.mobileMenuItem}
        button
        classes={{ selected: classes.selectedMobileMenuItem }}
        onClick={() => {
          setDrawerOpen(false);
          openModal(APP_MODAL.LOGIN);
        }}
      >
        <ListItemText>Login</ListItemText>
      </ListItem>
      <ListItem
        className={classes.mobileMenuItem}
        button
        classes={{ selected: classes.selectedMobileMenuItem }}
        onClick={() => {
          setDrawerOpen(false);
          openModal(APP_MODAL.SIGN_UP);
        }}
      >
        <ListItemText>Sign Up</ListItemText>
      </ListItem>
    </React.Fragment>
  );

  const renderDesktopMenu = () => (
    <div className={classes.desktopMenu}>
      {HEADER_NAVIGATION_ITEMS.map(item => (
        <NavLink className={globalClasses.navLink} key={item.url} to={item.url}>
          <Button
            className={classNames(classes.desktopMenuItem, {
              [classes.selectedDesktopMenuItem]: location.pathname === item.url,
            })}
            color="inherit"
          >
            {item.title}
          </Button>
        </NavLink>
      ))}
      <StyledButton
        className={classes.becomeADealerButton}
        onClick={() => openModal(APP_MODAL.DEALER_MODAL)}
      >
        Become a Dealer
      </StyledButton>
      <IconButton
        aria-owns={accountMenuOpen ? 'account-menu' : undefined}
        onMouseOver={handleAccountButtonHover}
        onMouseLeave={() => setAccountMenuData({ ...accountMenuData, hover: false })}
        onFocus={() => {}}
        className={classes.accountMenuButton}
        onClick={evt => setAccountMenuData({ ...accountMenuData, anchorEl: evt.currentTarget })}
      >
        {isAuthenticated ? (
          <ProfileAvatar className={classes.profileAvatarDesktop} />
        ) : (
          <AccountCircle />
        )}
      </IconButton>
      {renderDesktopAccountMenu()}
    </div>
  );

  const renderMobileMenuButton = () => (
    <IconButton
      onClick={() => setDrawerOpen(!drawerOpen)}
      className={classes.mobileMenuButton}
      aria-label="Menu"
    >
      <MenuIcon />
    </IconButton>
  );

  const renderMobileDrawer = () => (
    <Drawer
      classes={{ paper: classes.mobileDrawerPaper }}
      anchor="right"
      open={drawerOpen}
      onClose={() => setDrawerOpen(false)}
    >
      <div className={classes.mobileDrawerHeader}>
        <IconButton onClick={() => setDrawerOpen(false)}>
          <ChevronRight />
        </IconButton>
      </div>
      <List className={classes.mobileDrawerMenu}>
        {renderMobileAccountMenuItems()}
        <Divider style={{ margin: '1rem 0' }} />
        {HEADER_NAVIGATION_ITEMS.map((item) => {
          const Icon = (item.icon && item.icon()) || <ChevronRight />;
          return (
            <NavLink
              key={item.url}
              className={globalClasses.navLink}
              to={item.url}
              onClick={() => setDrawerOpen(false)}
            >
              <ListItem
                className={classes.mobileMenuItem}
                button
                classes={{ selected: classes.selectedMobileMenuItem }}
                selected={location.pathname === item.url}
              >
                <ListItemIcon className={classes.mobileMenuItemIcon}>{<Icon />}</ListItemIcon>
                <ListItemText>{item.title}</ListItemText>
              </ListItem>
            </NavLink>
          );
        })}

        {/* TODO: Add user profile route */}
      </List>
      <StyledButton
        className={classes.becomeADealerButton}
        onClick={() => {
          setDrawerOpen(false);
          openModal(APP_MODAL.DEALER_MODAL);
        }}
      >
        Become a Dealer
      </StyledButton>
      <div />
      {/* <NavLink className={classes.drawerAppLogo} to={Routes.home.url}>
        <img alt="FLIP" src={usedLogo} />
      </NavLink> */}
    </Drawer>
  );

  return (
    <AppBar color="primary" className={classes.appBar} position="fixed">
      <Toolbar className={classes.toolBar}>
        <NavLink className={classes.appLogo} to={Routes.home.url}>
          <img alt="FLIP" src={usedLogo} />
        </NavLink>

        {isDesktop ? (
          renderDesktopMenu()
        ) : (
          <React.Fragment>
            {renderMobileMenuButton()}
            {renderMobileDrawer()}
          </React.Fragment>
        )}
      </Toolbar>
    </AppBar>
  );
};

const mapState = ({ userReducer }) => ({ user: userReducer.user });

const mapDispatch = { openModal };

export default withRouter(
  connect(
    mapState,
    mapDispatch,
  )(Header),
);
