import _ from 'lodash';
import React from 'react';
import { Link } from '@material-ui/core';
import { connect } from 'react-redux';
import Modal from './Modal';
import StyledButton from '../../ui/StyledButton';
import ForgotPasswordForm from '../forms/ForgotPasswordForm';
import { openModal } from '../../../core/reducers/app/actions';
import { APP_MODAL } from '../../../core/reducers/app/enums';
import { isValidEmail } from '../../utils/Helper';

const ForgotPasswordModal = ({ openModal, ...otherProps }) => {
  const formRef = React.createRef();

  const handleSubmit = () => {
    if (!formRef || !formRef.current) return;

    const { email } = formRef.current;

    const errors = {};

    // Validate fields
    if (!email || !isValidEmail(email)) errors.email = 'You must enter a valid email.';

    if (!_.isEmpty(errors)) return formRef.current.setErrors(errors);

    // TODO: FORGOT PASSWORD API CALL
  };

  const footer = (
    <Link href="#" variant="subtitle1" onClick={() => openModal(APP_MODAL.LOGIN)}>
      Back to Sign In
    </Link>
  );

  return (
    <Modal
      fullWidth
      maxWidth="xs"
      title="RESET PASSWORD"
      description="Please enter your email address and we'll send you a link to reset your password."
      footer={footer}
      content={<ForgotPasswordForm ref={formRef} />}
      actions={(
        <StyledButton fullWidth size="large" onClick={handleSubmit}>
          Submit
        </StyledButton>
)}
      {...otherProps}
    />
  );
};

const mapDispatch = {
  openModal,
};

export default connect(
  null,
  mapDispatch,
)(ForgotPasswordModal);
